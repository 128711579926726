import { getModule } from '@/store'
import { useRouter } from '@/vuexy/utils/utils'
import { ECommerceModule } from './eCommerceStoreModule'

export const useEcommerce = () => {
  const appEcommerce = getModule(ECommerceModule)

  // eslint-disable-next-line arrow-body-style
  const addProductInWishlist = (productId: string) => {
    return appEcommerce.addProductInWishlist({ productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromWishlist = (productId: string) => {
    return appEcommerce.removeProductFromWishlist({ productId })
  }

  // eslint-disable-next-line arrow-body-style
  const addProductInCart = (productId: string) => {
    return appEcommerce.addProductInCart({ productId })
  }

  // eslint-disable-next-line arrow-body-style
  const removeProductFromCart = (productId: string) => {
    return appEcommerce.removeProductFromCart({ productId })
  }

  return {
    addProductInWishlist,
    removeProductFromWishlist,
    addProductInCart,
    removeProductFromCart,
  }
}

export const useEcommerceUi = () => {
  const { router } = useRouter()
  const appEcommerce = getModule(ECommerceModule)

  const toggleProductInWishlist = (product: any) => {
    const { addProductInWishlist, removeProductFromWishlist } = useEcommerce()
    if (product.isInWishlist) {
      removeProductFromWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = false
      })
    } else {
      addProductInWishlist(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInWishlist = true
      })
    }
  }

  const handleCartActionClick = (product: any) => {
    const { addProductInCart } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      addProductInCart(product.id).then(() => {
        // eslint-disable-next-line no-param-reassign
        product.isInCart = true

        // Update cart items count
        appEcommerce.UPDATE_CART_ITEMS_COUNT(appEcommerce.cartItemsCount + 1)
      })
    }
  }

  const handleWishlistCartActionClick = async (product: any, removeProductFromWishlistUi: any) => {
    const { addProductInCart, removeProductFromWishlist } = useEcommerce()

    if (product.isInCart) {
      router.push({ name: 'apps-e-commerce-checkout' })
    } else {
      await addProductInCart(product.id)

      // eslint-disable-next-line no-param-reassign
      product.isInCart = true
      removeProductFromWishlist(product.id)

      // Update cart items count
      appEcommerce.UPDATE_CART_ITEMS_COUNT(appEcommerce.cartItemsCount + 1)

      // eslint-disable-next-line no-param-reassign
      product.isInWishlist = false
      removeProductFromWishlistUi(product)
    }
  }

  return {
    toggleProductInWishlist,
    handleCartActionClick,
    handleWishlistCartActionClick,
  }
}
